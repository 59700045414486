<template>
  <modal name="update-address" class="modal-inner modal-update-address" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true">
    <span class="close-button" @click="hide('update-address')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">{{ $t("customer_info.customer_data") }}</div>
    <div class="modal-body">
      <ValidationObserver ref="formUpdateAddress" v-if="customerInfo != null">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="customerInfo.name" type="text" :field_name="$t('customer_info.business_name')" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="customerInfo.company_number" type="text" :field_name="$t('customer_info.org_number')" rules="required" />
          </div>
          <div v-if="user.role == 'admin' && type == 'unit'" class="w-full md:w-1/2 px-2">
            <BaseSelect v-model="customerInfo.seller_id" :options="sellers" optionSelector="_id" nameSelector="name" :field_name="$t('customer_info.seller')" rules="required" />
          </div>
          <div v-if="user.role == 'admin' && type == 'unit'" class="w-full md:w-1/2 px-2">
            <BaseSelect v-model="customerInfo.alarm_central" :options="alarmCenters" optionSelector="_id" nameSelector="name" :field_name="$t('customer_info.alarm_center')" rules="required" />
          </div>

          <div class="w-full text-sm font-bold mt-5 mb-4 mx-2 pb-1 border-b border-secondary-400">{{ $t("customer_info.contact_details") }}</div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="customerInfo.contact_name" type="text" :field_name="$t('customer_info.name')" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-if="type == 'customer'" v-model="customerInfo.phone" type="text" :field_name="$t('customer_info.phone')" rules="required" />
            <BaseInput v-else v-model="customerInfo.contact_phone" type="text" :field_name="$t('customer_info.phone')" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-if="type == 'customer'" v-model="customerInfo.email" type="text" :field_name="$t('customer_info.email')" rules="required" />
            <BaseInput v-else v-model="customerInfo.contact_email" type="text" :field_name="$t('customer_info.email')" rules="required" />
          </div>

          <div class="w-full text-sm font-bold mt-5 mb-4 mx-2 pb-1 border-b border-secondary-400">{{ $t("customer_info.postal_address") }}</div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="customerInfo.address" type="text" :field_name="$t('customer_info.address')" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="customerInfo.zip_code" type="text" :field_name="$t('customer_info.zip_code')" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="customerInfo.city" type="text" :field_name="$t('customer_info.city_town')" rules="required" />
          </div>

          <div class="w-full text-sm font-bold mt-5 mb-4 mx-2 pb-1 border-b border-secondary-400">{{ $t("customer_info.billing_address") }}</div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="customerInfo.billing_address" type="text" :field_name="$t('customer_info.address')" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="customerInfo.billing_zip_code" type="text" :field_name="$t('customer_info.zip_code')" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="customerInfo.billing_city" type="text" :field_name="$t('customer_info.city_town')" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="customerInfo.billing_reference" type="text" :field_name="$t('customer_info.ref')" rules="" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="customerInfo.billing_invoice" type="text" :field_name="$t('customer_info.e_invoice')" rules="" />
          </div>

          <div v-if="type == 'unit'" class="w-full text-sm font-bold mt-5 mb-4 mx-2 pb-1 border-b border-secondary-400">{{ $t("customer_info.additional_services") }}</div>
          <div v-if="type == 'unit'" class="w-full px-2">
            <template v-for="s in unitSettingOptions">
              <div class="w-full md:w-1/2 py-2" v-bind:key="s.id">
                <label class="inline-flex items-center mt-1">
                  <input v-model="customerInfo.unitSettings" :value="s.id" type="checkbox" class="form-checkbox h-4 w-4" />
                  <span class="ml-2 text-gray-600 text-sm font-serif font-bold">{{ s.name }}</span>
                </label>
              </div>
            </template>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide('update-address')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" @click="updateAddress()">{{ $t("user_actions.save") }}<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 800;

export default {
  props: {
    type: {
      type: String,
      required: true,
      default: "unit",
    },
    customerInfo: {
      required: true,
      default: null,
    },
    alarmCenters: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      user: this.$store.state.user,
      sellers: [],
      unitSettingOptions: [{ id: "discreet_internal_alert", name: this.$t("new_department.discreet_internal_alert") }],
    };
  },
  methods: {
    updateAddress() {
      this.$refs.formUpdateAddress.validate().then((success) => {
        if (!success) {
          return;
        }

        let apiUrl = null;

        if (this.type == "customer") apiUrl = `${process.env.VUE_APP_SERVER_URL}/customer/address/${this.customerInfo._id}`;
        else apiUrl = `${process.env.VUE_APP_SERVER_URL}/unit/address/${this.customerInfo._id}`;

        this.axios
          .put(apiUrl, this.customerInfo)
          .then((response) => {
            this.$emit("updateAddress", response.data.data);
            this.hide("update-address");
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    getSellersResellers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/seller/reseller`)
        .then((response) => {
          this.sellers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    hide(id) {
      this.$refs.formUpdateAddress.reset();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },

  mounted() {
    if (this.user.role == "admin") this.getSellersResellers();
  },
};
</script>
