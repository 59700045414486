<template>
  <modal name="modal-order-alarm" class="modal-inner modal-order-alarm" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true">
    <span class="close-button" @click="hide('modal-order-alarm')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header">
      {{ $t("order_new.order_new_alarm") }}
    </div>
    <ValidationObserver ref="formOrderAlarm">
      <div class="modal-body">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="order_alarm.customer_name" type="text" :field_name="$t('order_new.customer_name')" readonly disabled />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="order_alarm.company_number" type="text" :field_name="$t('order_new.org_number')" readonly disabled />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="order_alarm.unit_name" type="text" :field_name="$t('order_new.business_name')" readonly disabled />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="order_alarm.unit_manager" type="text" :field_name="$t('order_new.business_manager')" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="order_alarm.phone" type="text" :field_name="$t('order_new.telematics_number')" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2">
            <div class="flex flex-col">
              <div class="w-full text-gray-600 text-sm font-bold pb-5 font-serif">
                {{ $t("order_new.article") }}
              </div>
              <div class="w-full flex flex-wrap">
                <div class="w-3/6 text-sm">{{ $t("order_new.personal_alarm") }}</div>
                <div class="w-2/6">
                  <ValidationProvider :name="$t('order_new.personal_alarm')" rules="required" v-slot="{ classes, errors }">
                    <div class="input-validate" :class="classes">
                      <input type="number" v-model="order_alarm.quantity.alarms" min="0" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="w-1/6"><span class="pl-2 text-sm">st</span></div>
              </div>
              <div class="w-full flex flex-wrap ">
                <div class="w-3/6 text-sm">{{ $t("order_new.indoor_positioning") }}</div>
                <div class="w-2/6">
                  <ValidationProvider :name="$t('order_new.indoor_positioning')" rules="required" v-slot="{ classes, errors }">
                    <div class="input-validate" :class="classes">
                      <input type="number" v-model="order_alarm.quantity.rfTags" min="0" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="w-1/6"><span class="pl-2 text-sm">st</span></div>
              </div>
              <div class="w-full flex flex-wrap ">
                <div class="w-3/6 text-sm">{{ $t("order_new.alarm_display") }}</div>
                <div class="w-2/6">
                  <ValidationProvider :name="$t('order_new.alarm_display')" rules="required" v-slot="{ classes, errors }">
                    <div class="input-validate" :class="classes">
                      <input type="number" v-model="order_alarm.quantity.alarmDisplay" min="0" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="w-1/6"><span class="pl-2 text-sm">st</span></div>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/2 px-2">
            <div class="flex flex-col">
              <div class="w-full text-gray-600 text-sm font-bold pb-5 font-serif">
                {{ $t("order_new.additional_functions") }}
              </div>
              <ValidationProvider name="ManDown" rules="oneOf:Ja,Nej" v-slot="{ classes, errors }">
                <div class="w-full flex flex-wrap">
                  <div class="w-4/6 text-sm">{{ $t("order_new.man_down") }}</div>
                  <div class="w-1/6">
                    <div class="flex items-center">
                      <div class="bg-white dark:bg-gray-100 rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center relative">
                        <input value="Ja" v-model="order_alarm.additionalFeatures.mandown" type="radio" class="rounded-full absolute cursor-pointer w-full h-full checked:border-none" />
                      </div>
                      <label class="ml-2 text-sm leading-4 font-sans">{{ $t("yes") }}</label>
                    </div>
                  </div>
                  <div class="w-1/6">
                    <div class="flex items-center">
                      <div class="bg-white dark:bg-gray-100 rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center relative">
                        <input value="Nej" v-model="order_alarm.additionalFeatures.mandown" type="radio" class="rounded-full absolute cursor-pointer w-full h-full checked:border-none" />
                      </div>
                      <label class="ml-2 text-sm leading-4 font-sans">{{ $t("no") }}</label>
                    </div>
                  </div>
                </div>
                <div class="text-right pr-3 mb-5" :class="classes">
                  <span class="text-xxs text-red-500">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider name="Internlarm" rules="oneOf:Ja,Nej" v-slot="{ classes, errors }">
                <div class="w-full flex flex-wrap">
                  <div class="w-4/6 text-sm">{{ $t("order_new.sms_email") }}</div>
                  <div class="w-1/6">
                    <div class="flex items-center">
                      <div class="bg-white dark:bg-gray-100 rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center relative">
                        <input value="Ja" v-model="order_alarm.additionalFeatures.internalAlarm" type="radio" class="rounded-full absolute cursor-pointer w-full h-full checked:border-none" />
                      </div>
                      <label class="ml-2 text-sm leading-4 font-sans">{{ $t("yes") }}</label>
                    </div>
                  </div>
                  <div class="w-1/6">
                    <div class="flex items-center">
                      <div class="bg-white dark:bg-gray-100 rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center relative">
                        <input value="Nej" v-model="order_alarm.additionalFeatures.internalAlarm" type="radio" class="rounded-full absolute cursor-pointer w-full h-full checked:border-none" />
                      </div>
                      <label class="ml-2 text-sm leading-4 font-sans">{{ $t("no") }}</label>
                    </div>
                  </div>
                </div>
                <div class="text-right pr-3 mb-5" :class="classes">
                  <span class="text-xxs text-red-500">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider name="9 Vibration" rules="oneOf:Ja,Nej" v-slot="{ classes, errors }">
                <div class="w-full flex flex-wrap">
                  <div class="w-4/6 text-sm">9 Vibration</div>
                  <div class="w-1/6">
                    <div class="flex items-center">
                      <div class="bg-white dark:bg-gray-100 rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center relative">
                        <input value="Ja" v-model="order_alarm.additionalFeatures.nineVib" type="radio" class="rounded-full absolute cursor-pointer w-full h-full checked:border-none" />
                      </div>
                      <label class="ml-2 text-sm leading-4 font-sans">{{ $t("yes") }}</label>
                    </div>
                  </div>
                  <div class="w-1/6">
                    <div class="flex items-center">
                      <div class="bg-white dark:bg-gray-100 rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center relative">
                        <input value="Nej" v-model="order_alarm.additionalFeatures.nineVib" type="radio" class="rounded-full absolute cursor-pointer w-full h-full checked:border-none" />
                      </div>
                      <label class="ml-2 text-sm leading-4 font-sans">{{ $t("no") }}</label>
                    </div>
                  </div>
                </div>
                <div class="text-right pr-3" :class="classes">
                  <span class="text-xxs text-red-500">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full px-2">
            <BaseTextarea rows="4" v-model="order_alarm.otherInfo" type="text" :field_name="$t('order_new.other_info')" rules="required" />
          </div>
          <div class="w-full px-2 mt-4">
            <BaseTextarea rows="2" v-model="order_alarm.workingInstruction" type="text" :field_name="$t('order_new.job_description')" rules="required" />
          </div>
        </div>
        <div class="flex flex-wrap mt-4">
          <div class="w-full md:w-1/2 px-2">
            <BaseSelect v-model="order_alarm.simcard" :options="simOperators" :field_name="$t('order_new.sim_card')" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="order_alarm.numberOfUsers" type="number" min="0" :field_name="$t('order_new.number_of_employees')" rules="required" />
          </div>
          <div class="w-full md:w-1/2 mt-2 px-2">
            <BaseInput v-model="order_alarm.nameOfOrderer" type="text" :field_name="$t('order_new.name_of_client')" rules="required" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="footer-container clearfix">
          <button class="btn-red-outline px-3 py-2 float-left" @click="hide('modal-order-alarm')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>
          <button class="btn-blue px-3 py-2 float-right" @click="orderNewAlarm()">{{ $t("order_new.order") }}<BaseIcon icon="arrow-right" class="ml-1" /></button>
        </div>
      </div>
    </ValidationObserver>
  </modal>
</template>

<script>
const MODAL_WIDTH = 800;

export default {
  name: "orderAlarmModal",
  props: {
    unit_id: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      customers: [],
      units: [],
      customer: null,
      unit: null,
      simOperators: ["Telia", "Telia DCP", "Telenor", "Tele2", "Kundens Simkort"],
      order_alarm: {
        customer_name: "",
        company_number: "",
        unit_name: "",
        unit_id: "",
        unit_manager: "",
        phone: "",
        quantity: {
          alarms: "",
          rfTags: "",
          alarmDisplay: "",
        },
        additionalFeatures: {
          mandown: false,
          internalAlarm: false,
          nineVib: false,
        },
        otherInfo: "",
        workingInstruction: "",
        simcard: "",
        numberOfUsers: "",
        nameOfOrderer: "",
      },
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    async getUnit() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/dashboard/customer/`);
        this.customers = response.data.customers;
        this.units = response.data.units;

        let unit = _.find(this.units, { unit_id: this.unit_id });
        if (unit) {
          this.unit = unit;

          this.order_alarm.unit_name = this.unit.name;
          this.order_alarm.unit_id = this.unit_id;
          this.order_alarm.unit_manager = this.unit.operation_manager;
          this.order_alarm.phone = this.unit.contact_phone;

          let customer = _.find(this.customers, { _id: unit.parent_id });
          if (customer) {
            this.customer = customer;

            this.order_alarm.customer_name = this.customer.name;
            this.order_alarm.company_number = this.customer.company_number;
          }
        }

        this.order_alarm.nameOfOrderer = `${this.user.firstname} ${this.user.firstname}`;
      } catch (error) {
        this.handleError(error);
      }
    },

    orderNewAlarm() {
      this.$refs.formOrderAlarm.validate().then(async (success) => {
        if (!success) {
          return;
        }

        try {
          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/customer/order-alarm/${this.customer._id}`, this.order_alarm);

          this.handleSuccess(response.data.message);
          this.hide("modal-order-alarm");
          this.resetForm();
        } catch (error) {
          this.handleError(error);
        }
      });
    },

    resetForm() {
      this.order_alarm = {
        customer_name: this.customer ? this.customer.name : "",
        company_number: this.customer ? this.customer.company_number : "",
        unit_name: this.unit ? this.unit.name : "",
        unit_id: this.unit_id,
        unit_manager: this.unit ? this.unit.operation_manager : "",
        phone: this.unit ? this.unit.contact_phone : "",
        quantity: {
          alarms: "",
          rfTags: "",
          alarmDisplay: "",
        },
        additionalFeatures: {
          mandown: false,
          internalAlarm: false,
          nineVib: false,
        },
        otherInfo: "",
        workingInstruction: "",
        simcard: "",
        numberOfUsers: "",
        nameOfOrderer: `${this.user.firstname} ${this.user.firstname}`,
      };
      this.$refs.formOrderAlarm.reset();
    },

    show(id) {
      this.$modal.show(id);
    },

    hide(id) {
      this.$refs.formOrderAlarm.reset();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;

    if (this.user.role == "superuser" || this.user.role == "user") {
      this.getUnit();
    }
  },
};
</script>
