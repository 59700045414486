<template>
  <div class="bg-primary-50 px-4 py-5 flex flex-col">
    <div class="clearfix border-b border-secondary-500 pb-2 mb-3">
      <div class="float-left text-lg text-bold font-serif">
        {{ $t("dashboard_seller.customer_data") }}
      </div>
      <div class="float-right">
        <button class="btn-blue-outline font-bold" @click="show('update-address')">{{ $t("dashboard_seller.change") }}</button>
      </div>
    </div>
    <div class="flex flex-wrap text-sm" v-if="customer_info != null">
      <div class="w-full md:w-1/2 lg:w-1/4 px-2 py-2">
        <div class="font-bold mb-1 font-serif">{{ $t("dashboard_seller.name") }}</div>
        <div class="mb-1">{{ customer_info.name }}</div>
        <div class="mb-1">{{ customer_info.company_number }}</div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 px-2 py-2">
        <div class="font-bold mb-1 font-serif">{{ $t("dashboard_seller.address") }}</div>
        <div class="mb-1">{{ customer_info.address }}</div>
        <div class="mb-1">{{ customer_info.zip_code }}</div>
        <div class="mb-1">{{ customer_info.city }}</div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 px-2 py-2">
        <div class="font-bold mb-1 font-serif">{{ $t("dashboard_seller.customer_data") }}</div>
        <div class="mb-1">{{ customer_info.contact_name }}</div>
        <div v-if="type == 'customer'" class="mb-1">{{ customer_info.email }}</div>
        <div v-else class="mb-1">{{ customer_info.contact_email }}</div>
        <div v-if="type == 'customer'" class="mb-1">{{ customer_info.phone }}</div>
        <div v-else class="mb-1">{{ customer_info.contact_phone }}</div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/4 px-2 py-2">
        <div class="font-bold mb-1 font-serif">{{ $t("dashboard_seller.billing_address") }}</div>
        <div class="mb-1">{{ customer_info.billing_address }}</div>
        <div class="mb-1">{{ customer_info.billing_zip_code }}</div>
        <div class="mb-1">{{ customer_info.billing_city }}</div>
        <div class="mb-1">{{ customer_info.billing_reference }}</div>
        <div class="mb-1">{{ customer_info.billing_invoice }}</div>
      </div>
    </div>
    <UpdateCustomerInformation :type="type" :customerInfo="updateInfo" :alarmCenters="alarmCenters" @updateAddress="updateAddress" />
  </div>
</template>

<script>
import UpdateCustomerInformation from "@/components/modal/customer_info_update";

export default {
  components: {
    UpdateCustomerInformation,
  },
  props: {
    type: {
      type: String,
      required: true,
      default: "unit",
    },
    customerInfo: {
      required: true,
      default: null,
    },
    alarmCenters: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      user: this.$store.state.user,
      customer_info: this.customerInfo,
      updateInfo: null,
    };
  },

  methods: {
    updateAddress(data) {
      this.customer_info = data;
    },
    show(id) {
      this.updateInfo = _.cloneDeep(this.customer_info);
      this.$modal.show(id);
    },
  },
};
</script>
